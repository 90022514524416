//require('./bootstrap');

/*
require('alpinejs');
*/

var checkbox = function(field, val) {
    field = document.getElementsByName(field+"[]");
    for (i = 0; i < field.length; i++) {
        field[i].checked = val;
    }
}

jQuery(document).ready(function($){
    $('.js-select-all').on('click', function(){
        checkbox($(this).data('name'), 1);
    });

    $('.js-select-none').on('click', function(){
        checkbox($(this).data('name'), 0);
    });

    $('[name=payment_method]').on('click', function(){
        switch($(this).val()){
            case '0' : //Rechnung
                $('.is_bankeinzug').attr('disabled', 'disabled');
                break;
            case '1' : //Bankeinzug
                $('.is_bankeinzug').removeAttr('disabled');
        }
    });

    $('[name=payment_method]:checked').trigger('click');
});




