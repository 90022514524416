var mycookie = Cookies.getJSON("isspmenu");
if (!mycookie){
    mycookie = {};
}


$(document).on('click', '.js-accordion-nav', function() {
    mycookie[$(this).attr('id')] = $(this).hasClass('collapsed');
    Cookies.set("isspmenu", mycookie, { expires: 365 });
});





