$(document).on('click', 'a[data-delete]', function(e){
    var title = $(this).data('title');
    if (!title){
        title = "Wirklich löschen? Der Vorgang kann nicht rückgängig gemacht werden.";
    }
    return window.confirm(title);
});

$(document).on('submit', 'form[data-delete]', function(e){
    var title = $(this).data('title');
    if (!title){
        title = "Wirklich löschen? Der Vorgang kann nicht rückgängig gemacht werden.";
    }
    return window.confirm(title);
});

